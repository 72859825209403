.followModal {
  height: 1024px;
  max-height: 90vh;
  width: 1024px;
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  overflow-y: hidden;
}

.followHeader {
  margin: 42px 60px 20px 36px;
  font-size: 24px;
  font-weight: 700;
}

.scrollWrapper {
  overflow-y: scroll;
  height: 76vh;
}

.designerList {
  margin-top: 50px;
  margin-left: 36px;
  margin-right: 24px;
}

.designerList::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.designerList::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: 46px;
}

.designerCard {
  display: flex;
  flex-direction: row;
  margin-bottom: 45px;
  margin-right: 0;
}

.designerProfile {
  height: 100px;
  width: 100px;
  border-radius: 100%;
  background-size: cover;
}

.designerDetails {
  margin-left: 40px;
}

.designerName {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.01em;
}

.designerFollowerCount {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #8b8b8b;
}

.followButton {
  margin-top: 20px;
  cursor: pointer;
  width: 114px;
  height: 36px;

  border: 2px solid #ef6971;
  border-radius: 32px;
  font-family: Helvetica Neue;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  background-color: #ffffff;
  color: #ef6971;
  transition: all 0.5s;
}

.followButton:hover {
  background-color: #ef6971;
  color: #ffffff;
}

.followButtonFollowing {
  composes: followButton;
  color: #8b8b8b;
  background-color: #ededed;
  border-color: #ededed;
}

.followButtonFollowing:hover {
  color: #8b8b8b;
  background-color: #ededed;
}

.inspirations {
  display: flex;
  margin-left: auto;
}

.inspirationImage {
  height: 106px;
  width: 106px;
  background-size: cover;
  border-radius: 10px;
  margin-left: 14px;
}

.closeButton {
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .followModal {
    width: 90vw;
    height: 80vh;
    margin-top: 5vh;
  }

  .followHeader {
    margin: 27px 32px 0;
  }

  .designerList {
    margin-top: 24px;
    margin-left: 32px;
    margin-right: 32px;
    height: auto;
    box-sizing: border-box;
  }

  .designerCard {
    margin-right: 32px;
  }

  .designerDetails {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    height: 72px;
    flex: 1;
  }

  .designerName {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.16px;
  }

  .designerFollowerCount {
    color: #8b8b8b;
    margin: 0;
    font-family: Helvetica Neue;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.14px;
    margin-top: 8px;
  }

  .followButton {
    height: 32px;
    width: 90px;
    font-size: 12px;
    margin-left: auto;
    margin: 0;
  }

  .inspirations {
    margin-left: 0;
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .inspirationImage {
    width: 25vw;
    height: 25vw;
    margin-left: 0;
  }

  .inspirationImage:first-child {
    margin-left: 0;
  }

  .designerCard {
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid #e5e5e5;
  }
}

@media (max-width: 600px) {
  .followModal {
    margin-top: 0;
  }

  .designerList {
    padding-bottom: 100px;
  }

  .designerList {
    margin-top: 28px;
    margin-left: 16px;
    margin-right: 16px;
    height: auto;
  }

  .followModal {
    height: calc(100vh - 60px);
    max-height: none;
    width: 100vw;
    border-radius: 0;
    overflow-y: scroll;
  }

  .scrollWrapper {
    overflow: visible;
  }

  .closeButton {
    right: 8px;
    width: 28px;
    height: 28px;
  }

  .followHeader {
    margin: 16px 28px 0px 16px;
    font-size: 18px;
  }

  .designerCard:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .designerProfile {
    height: 72px;
    width: 72px;
  }

  .inspirationImage {
    flex: 0 1 auto;
    margin: 0;
  }
}
