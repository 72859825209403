.giftNum {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-right: 15px;
  color: #8b8b8b;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 1;
  margin-right: 20px;
}

.giftNum img {
  width: 31px;
  height: 29px;
  margin-top: -5px;
  margin-right: 4px;
}

.closeButton {
  position: absolute;
  height: 40px;
  width: 40px;
  right: 8px;
  top: 8px;
}

.giftHeader {
  margin-top: 68px;
  margin-left: 36px;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.01em;
}

.coinBalance {
  margin-top: 68px;
  margin-left: 6px;
  margin-right: 36px;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}

.sendButton {
  width: 180px;
  height: 42px;
  background: #ff5e6d;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  border-radius: 37px;
  border: none;
  position: absolute;
  bottom: 30px;
  left: calc(50% - 90px);
  cursor: pointer;
}

.sendButtonGift {
  width: 180px;
  height: 42px;
  background: #ff5e6d;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  border-radius: 37px;
  border: none;
  position: absolute;
  bottom: 20px;
  left: calc(50% - 90px);
}

.sendButton:disabled {
  background: #c4c4c4;
}

.commentGiftImg {
  position: absolute;
  border-radius: 50%;
  top: 29px;
  left: 28px;
  width: 26px !important;
  height: 24px !important;
}

.userAvatar {
  position: relative;
  width: 61px;
  height: 61px;
  padding: 24px;
}

.userAvatar img {
  width: 61px;
  height: 61px;
}

.levelBadge {
  position: absolute;
  background: #ff5e6d;
  border-radius: 52px;
  width: 30px;
  height: 16px;
  top: 75px;
  left: 55px;

  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
}

.reportModal {
  width: 530px;
  height: 500px;
  background: #ffffff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.reportTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  letter-spacing: 0.01em;
  margin-top: 44px;
  color: #000000;
}

.commentFooterInput input {
  padding: 20px;
  width: 520px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 5px;
}

.reportContent {
  padding: 20px 40px;
  overflow: auto;
  width: 85%;
}

.submitButton {
  height: 42px;
  background: #b5b5b5;
  cursor: pointer;
  width: 160px;
  height: 42px;
  border-radius: 32px;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  border: none;
  transition: 0.3s;
}

.submitButton:hover {
  background: #ff5e6d;
}

.submitButtonActive {
  background: #ff5e6d;
}

.radioContent {
  display: flex;
  align-items: center;
}

.radioButton {
  margin: 0 15px 0 0;
  width: 20px;
  height: 20px;
}

.radioLabel {
  font-family: Helvetica Neue;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 42px;
  display: flex;
  align-items: center;
  color: #000000;
}

.giftAllItems {
  width: 680px;
  height: 680px;
  background-color: #ffffff;
  border-radius: 20px;
  position: relative;
  padding: 0 20px 20px;
  box-sizing: border-box;
}

.giftAllItemsTitle {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  margin-top: 44px;
  color: #000000;
}

.giftAllItemsContent {
  width: 100%;
  height: 490px;

  margin-top: 20px !important;
  background-color: #ffffff;
  margin: auto;
  overflow-y: auto;
}

.giftAllItemsContent::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
.giftAllItemsContent::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 46px;
}

.giftAllItemsPhoto {
  cursor: pointer;
  position: relative;
}

.giftAllItemsPhoto > img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.giftAllItemsPhoto > a > img {
  border-radius: 50%;
  width: 72px;
  height: 72px;
}

.giftImage {
  position: absolute;
  border-radius: 50%;
  top: 30px;
  left: 32px;
  width: 25px !important;
  height: 25px !important;
}

.giftAllItemsBody {
  display: flex;
  align-items: center;
  height: 96px;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
}

.giftAllItemsInfo {
  display: flex;
  justify-content: space-around;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 29px;
}

.userInfo p {
  margin: 0;
}

.userName {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  /* identical to box height */
  display: flex;
  align-items: center;
  margin-bottom: 10px !important;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}

.followingDate {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: #8b8b8b;
}

.followButton {
  width: 90px;
  height: 30px;
  border: 2px solid #ef6971;
  border-radius: 32px;

  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  background-color: #ffffff;
  color: #ef6971;
  transition: all 0.5s;
}

.followButton:hover {
  background-color: #ef6971;
  color: #ffffff;
}

.followButtonFollowing {
  composes: followButton;
  color: #ffffff;
  background-color: #c4c4c4;
  border-color: #c4c4c4;
}

.followButtonFollowing:hover {
  color: #8b8b8b;
  background-color: #ededed;
}

@media only screen and (max-width: 700px) {
  .giftAllItems {
    width: 100vw;
    height: 80vh;
    top: 20vh;
    background-color: #ffffff;
    border-radius: 20px;
    position: relative;
    animation-name: slider1;
    animation-duration: 0.5s;
  }
  .reportModal {
    width: 320px;
    margin: auto;
  }

  .reportContent {
    padding: 20px 20px;
  }

  .reportTitle {
    font-size: 18px;
  }

  .giftHeader {
    font-size: 18px;
    font-weight: bold;
  }

  .coinBalance {
    font-size: 14px;
    font-weight: bold;
  }

  @keyframes slider1 {
    0% {
      top: calc(100vh);
    }
    100% {
      top: 20vh;
    }
  }

  .sendButtonGift {
    bottom: 70px;
  }

  .floatButtons > div {
    width: 51px !important;
    height: 51px !important;
  }
  .giftList {
    height: 100vh;
  }
  @keyframes slider {
    0% {
      top: calc(100vh);
    }
    100% {
      top: calc(51px);
    }
  }

  .sendButton {
    width: 180px;
    height: 42px;
    background: #ff5e6d;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    border-radius: 37px;
    border: none;
    position: absolute;
    bottom: 90px;
    left: calc(50vw - 90px);
  }
}
