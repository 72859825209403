.ov {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  height: 100vh;
  width: 100vw;
  z-index: 999;
}

.centered {
  justify-content: center !important;
  align-items: center !important;
}

@media (max-width: 1000px), (max-height: 648px) {
  .ov {
    margin-top: 60px;
  }
}

.lo {
  background-color: white;
  border-radius: 0;
  width: 100%;
  overflow: hidden;
  box-shadow: none;
  max-height: 100%;
  overflow-y: auto;
  height: 100vh;
  min-width: 375px;
}

.lom {
  background-image: url(../../../assets/modal-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: white;
  border-radius: 0;
  overflow: hidden;
  box-shadow: none;
  max-height: 100%;
  overflow-y: auto;
  height: auto;
  width: 90vw;
  box-sizing: border-box;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 10vh;
}

.lom.hideBg {
  background-image: none;
}

.lom.hideBg h3 {
  font-size: 15px;
}

.clm {
  width: 100%;
  text-align: right;
  position: absolute;
  top: 10px;
  right: 10px;
}

.cl {
  position: absolute;
  top: 20px;
  right: 20px;
}

.cli {
  display: block;
  fill: #eee;
  height: 20px;
  width: 20px;
}

.clb {
  outline: none;
  text-decoration: none;
  background-color: transparent;
  border: none;
}

.inw {
  position: relative;
  background-image: url(../../../assets/images/logbg01.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.indd {
  padding: 20px 40px 0 40px;
  text-align: center;
}

.inb {
  padding-top: 15px;
}

.bd {
  padding: 0 40px;
}

.orw {
  text-align: center;
  display: flex;
  flex-direction: row;
  padding: 20px 0;
}

.orb {
  flex-grow: 1;
  text-align: center;
  display: flex;
  align-items: center;
}

.orbl {
  background-color: #c4c4c4;
  height: 1px;
  width: 100%;
}

.or {
  text-align: center;
  background-color: white;
  padding: 0 20px;
  color: #c4c4c4;
  font-weight: 500;
  font-size: 14px;
}

.acc {
  text-align: center;
  padding: 20px;
}

.sc {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.bsu {
  padding-top: 20px;
}

.inco {
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .low {
    justify-content: flex-start;
    align-items: flex-start;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .lo {
    width: 600px;
    border-radius: 5px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    height: auto;
  }
  .lom {
    top: unset;
    min-width: 600px;
    width: auto;
    box-sizing: border-box;
    border-radius: 5px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.3);
    height: auto;
  }
  .inco {
    padding: 10px 60px 60px 60px;
  }
  .indd {
    padding: 40px 80px 0 80px;
  }
  .inb {
    padding-top: 30px;
  }
  .bd {
    padding: 0 80px;
  }
  .acc {
    text-align: center;
    padding: 40px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* iphone 5 */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) {
  .clm {
    width: 90%;
  }
}
